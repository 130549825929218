import {
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  ConfigProvider,
  Descriptions,
  Image,
  Layout,
  notification,
  Row,
  Space,
  Typography,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import axios from "./api/axios";
import { MainContext } from "./App";
import moment from "moment/moment";
import sha1 from "js-sha1";
import backImage from "./images/cat1.png";
import principaleAreaUtente from "./images/OIGgrey.png";
import "./index.css";
const { Title, Text } = Typography;
const { Sider, Content } = Layout;
const styles = {
  fontwhite: {
    color: "white",
  },
};
const AreaUtente = () => {
  const [api, contextHolder] = notification.useNotification();
  const { datiUtente, setDatiUtente } = useContext(MainContext);
  const [schede, setSchede] = useState();
  // const [mac, setMac] = useState();
  // const [codTrans, setCodTrans] = useState();
  console.log("datiUtente in Area Utente", datiUtente);

  useEffect(() => {
    let utenteLocal = localStorage.getItem("utente");
    setDatiUtente(JSON.parse(utenteLocal));
  }, []);
  useEffect(() => {
    let localSchede = JSON.parse(localStorage.getItem("schede"));
    if (localSchede !== null) {
      setSchede(localSchede);
    } else {
      getSchedeUtente();
    }
  }, []);

  async function getSchedeUtente() {
    await axios
      .get(`api/Main/Schede/${datiUtente?.pK_Utente}`)
      .then((res) => {
        let data = res.data;
        console.log("data", data);
        setSchede(data);
        localStorage.setItem("schede", JSON.stringify(data));
        api.success({
          message: `Schede caricate con successo`,
        });
      })
      .catch((error) =>
        api.error({
          message: `${error}`,
        })
      );
  }

  return (
    <div>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#00b96b",
            colorSecondary: "#fffff !important",
            colorTextSecondary: "white",
          },
        }}
      >
        <Layout style={{ minHeight: "100vh" }}>
          {contextHolder}
          <Card className='gradient' style={{ margin: "5px" }}>
            <Sider
              width={220}
              style={{ background: "transparent", color: "#fff" }}
            >
              <Space direction='vertical' style={{ width: "100%" }}>
                <Space direction='vertical' style={{ width: "100%" }}>
                  {/* Sidebar content */}
                  <Row
                    color='white'
                    align='center'
                    justify='center'
                    style={{ color: "#fff" }}
                  >
                    <Col span={20} style={{ textAlign: "center" }}>
                      <Title
                        level={4}
                        style={{ color: "#fff", marginTop: "1.5rem" }}
                      >
                        {datiUtente?.nome.charAt(0).toUpperCase() +
                          datiUtente?.nome.slice(1).toLowerCase()}
                        {/* Utente */}
                      </Title>
                      <Space wrap size={1}>
                        <Badge count={1}>
                          <Avatar
                            size={80}
                            style={{ backgroundColor: "lightgrey" }}
                            icon={<UserOutlined />}
                          ></Avatar>
                        </Badge>
                      </Space>
                    </Col>
                  </Row>
                </Space>
                <Row
                  justify='start'
                  align='bottom'
                  style={{ marginLeft: "8%" }}
                >
                  <Space direction='vertical' size='middle'>
                    <Col>
                      <Space>
                        <Text style={{ color: "#fff" }} strong>
                          Nome:
                        </Text>
                        <Text style={styles.fontwhite}>{datiUtente?.nome}</Text>
                      </Space>
                    </Col>

                    <Col>
                      <Space>
                        <Text style={styles.fontwhite} strong>
                          Cognome:
                        </Text>
                        <Text style={styles.fontwhite}>
                          {datiUtente?.cognome}
                        </Text>
                      </Space>
                    </Col>
                    <Col>
                      <Space>
                        <Text style={styles.fontwhite} strong>
                          Altezza:
                        </Text>
                        <Text style={styles.fontwhite}>
                          {datiUtente?.altezza}
                        </Text>
                      </Space>
                    </Col>
                    <Col>
                      <Space>
                        <Text style={styles.fontwhite} strong>
                          Email:
                        </Text>
                        <Text style={styles.fontwhite}>
                          {datiUtente?.email}
                        </Text>
                      </Space>
                    </Col>
                  </Space>
                </Row>
              </Space>

              {/* Add your user information here */}
            </Sider>
          </Card>
          <Content>
            <Content
              className='headerUtente'
              style={{
                margin: "1rem 1rem",
                padding: "3rem 2rem",
                // backgroundColor: "#363636",
                borderRadius: "10px",
                // opacity: "0.5",
                backgroundImage: `url(${principaleAreaUtente})`,
                minHeight: "180px",
              }}
            >
              {/* Table content */}
              <Title level={2} style={{ color: "white", fontWeight: "bold" }}>
                Bentornato{" "}
                {datiUtente?.nome.charAt(0).toUpperCase() +
                  datiUtente?.nome.slice(1).toLowerCase()}
                {"!"}
              </Title>
            </Content>
            <Card
              style={{
                marginLeft: "2.5rem",
                marginRight: "2.5rem",
                marginTop: "-4rem",
              }}
            >
              <div
                style={{
                  // margin: "10px",
                  justifyContent: "space-between",
                  display: "flex",
                }}
              >
                <h3>Le mie schede:</h3>{" "}
                <div>
                  <div>
                    {" "}
                    <a
                      href='#'
                      class=''
                      style={{
                        padding: "10px",
                        borderRadius: "10px",
                        color: "white",
                        backgroundColor: "#363636",
                        borderColor: "white",
                      }}
                    >
                      Inizia percorso
                    </a>
                  </div>
                </div>
              </div>
            </Card>

            {/* <Table columns={columns} dataSource={utenti} /> */}
            <Content style={{ margin: "1rem" }}>
              <Row gutter={8}>
                <Col span={8}>
                  <Card
                    style={{
                      backgroundColor: "#000",
                      backgroundImage: `url(${backImage})`,
                      backgroundSize: "200px",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  >
                    <Space
                      direction='vertical'
                      align='center'
                      style={{ width: "100%" }}
                    >
                      <Text style={{ color: "#fff" }}>La mia scheda</Text>
                      <a
                        class='border-btn'
                        href='https://buy.stripe.com/test_eVa5n32Cjdrl9UIeUW'
                        style={{
                          color: "white",
                          padding: "14px",
                          fontSize: "14px",
                        }}
                      >
                        Scarica scheda
                      </a>
                    </Space>
                  </Card>
                </Col>
                {schede?.map((s) => {
                  return (
                    <Col span={8}>
                      <Card
                        style={{
                          backgroundColor: "#000",
                          backgroundImage: `url(${backImage})`,
                          backgroundSize: "200px",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                        }}
                      >
                        <Space
                          direction='vertical'
                          align='center'
                          style={{ width: "100%" }}
                        >
                          <Text style={{ color: "#fff" }}>La mia scheda</Text>
                          <a
                            class='border-btn'
                            href='https://buy.stripe.com/test_eVa5n32Cjdrl9UIeUW'
                            style={{
                              color: "white",
                              padding: "14px",
                              fontSize: "14px",
                            }}
                          >
                            Scarica scheda
                          </a>
                        </Space>
                      </Card>
                    </Col>
                    // <div class='col-xl-4 col-lg-4'>
                    //   <div class='single-topic text-center mb-30'>
                    //     <div class='topic-img'>
                    //       <img src='assets/img/gallery/cat1.png' alt='' />
                    //       <div class='topic-content-box'>
                    //         <div class='topic-content'>
                    //           <h3>Nome scheda:</h3>
                    //           <h3>{s.NomeScheda}</h3>
                    //           <p></p>
                    //           <a
                    //             class='border-btn'
                    //             href='https://buy.stripe.com/test_eVa5n32Cjdrl9UIeUW'
                    //             style={{
                    //               color: "white",
                    //               padding: "12px",
                    //               fontSize: "12px",
                    //             }}
                    //           >
                    //             Scarica scheda
                    //           </a>
                    //           {/* <a href={s.Link} class='border-btn'>
                    //             Scarica scheda
                    //           </a> */}
                    //         </div>
                    //       </div>
                    //     </div>
                    //   </div>
                    // </div>
                  );
                })}
              </Row>
            </Content>
          </Content>
          {/* <UtenteModal
        utenti={utenti}
        data={dataSchede}
        visible={modalVisible}
        onCancel={handleCancel}
      /> */}
        </Layout>
      </ConfigProvider>
    </div>
  );
};

export default AreaUtente;
